

















































































.box-share {
    text-align: center;
    padding: 50px 0px;
    .icon {
        display: inline-block;
        background: #ff694c;
        color: #fff;
        border-radius: 50%;
        padding: 26px;
    }
    .name {
        color: #394a55;
        font-weight: bold;
        font-size: 24px;
        margin: 20px 0px;
    }
}

